<template>
    <div class="terms-container">
      <h1>Kullanım Şartları ve Koşulları</h1>
      <p>Son Güncelleme: 30.07.2024</p>
  
      <h2>1. Giriş</h2>
      <p>Bu Kullanım Şartları ve Koşulları ("Şartlar"), Saveh Gold platformunun ("Platform") kullanımını düzenlemektedir. Bu platform, oyunlarda kullanılan "gold" ve benzeri adlar verilen oyun parasının alım ve satımını sağlamak amacıyla oluşturulmuştur. Platformu kullanarak bu Şartları kabul etmiş sayılırsınız. Kabul etmiyorsanız, Platformu kullanmamalısınız.</p>
  
      <h2>2. Hizmetlerin Tanımı</h2>
      <p>Saveh Gold, kullanıcıların oyunda kullanılan gold parasını alıp satabileceği bir platformdur. Tüm alım ve satım işlemleri, verilen IBAN ve hesap kodları üzerinden gerçekleşir.</p>
  
      <h2>3. Kayıt ve Hesap Güvenliği</h2>
      <p>Kullanıcıların Platforma erişim sağlamak için doğru ve eksiksiz bilgi vererek kayıt olmaları gerekmektedir. Hesap bilgilerinizin gizliliğinden siz sorumlusunuz. Hesabınızın yetkisiz kullanımını derhal bize bildirmelisiniz.</p>
  
      <h2>4. Alım Satım İşlemleri</h2>
      <p>Kullanıcılar, gold satın alırken verilen IBAN'a para gönderirler ve gold satarken verilen hesap koduna goldlarını gönderirler. Platformumuzda gold alınmadan para gönderilmemesi ve para gönderilmeden gold gönderilmemesi prensibi benimsenmiştir. Bu prensip, dolandırıcılığı önlemeye yönelik bir güvenlik tedbiridir.</p>
  
      <h2>5. İşlem Kayıtları</h2>
      <p>Tüm alım ve satım işlemleri miktar ve tarih bilgileriyle birlikte kaydedilmektedir. Kullanıcılar, yaptıkları işlemlerin kayıtlarına erişebilirler. Bu kayıtlar, olası anlaşmazlıkların çözümüne yardımcı olmak için saklanmaktadır.</p>
  
      <h2>6. Sorumluluk Reddi</h2>
      <p>Platform, kullanıcılar arasında gerçekleşen işlemlerden sorumlu değildir. Kullanıcılar, kendi aralarında yaptıkları işlemlerden kaynaklanan herhangi bir zarardan tamamen kendileri sorumludur. Platform, alım satım işlemlerinin güvenliğini sağlamak için gerekli tüm önlemleri almaktadır ancak işlemler sırasında oluşabilecek herhangi bir kayıptan sorumlu tutulamaz.</p>
  
      <h2>7. Yasal Uygunluk</h2>
      <p>Platformu kullanarak, yürürlükteki tüm yasal düzenlemelere uymayı kabul edersiniz. Finansal işlemlerle ilgili yasal düzenlemelere (KYC, AML) uymanız gerekmektedir. Bu tür düzenlemelere uymamanız halinde, hesabınız askıya alınabilir veya kapatılabilir.</p>
  
      <h2>8. Veri Güvenliği ve Gizlilik</h2>
      <p>Platform, kullanıcıların kişisel ve finansal bilgilerinin güvenliğini sağlamak için gerekli tüm tedbirleri alır. Kullanıcı bilgileri, yalnızca ilgili işlemlerin gerçekleştirilmesi amacıyla kullanılır ve üçüncü şahıslarla paylaşılmaz.</p>
  
      <h2>9. Sözleşme Değişiklikleri</h2>
      <p>Platform, bu Şartları herhangi bir zamanda değiştirme hakkını saklı tutar. Yapılan değişiklikler, kullanıcılara Platform üzerinde yayınlanır. Değişikliklerden sonra Platformu kullanmaya devam etmeniz, değişiklikleri kabul ettiğiniz anlamına gelir.</p>
  
      <h2>10. İletişim</h2>
      <p>Bu Şartlarla ilgili herhangi bir sorunuz varsa, bizimle iletişime geçmekten çekinmeyin: gorkem@savehgold.com.tr </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TermsAndConditions'
  }
  </script>
  
  <style scoped>
  .terms-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 50px;
    font-family: Arial, sans-serif;
  }
  
  h1, h2 {
    color: #333;
    font-size: 18px;
  }
  
  p {
    color: #666;
    line-height: 1.6;
    font-size: 12px;
  }
  </style>