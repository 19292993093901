import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index.js';
import axios from 'axios';
import AdsPage from '../components/AdsPage.vue';
import ProfilPage from '../components/ProfilPage.vue';
import WalletPage from '../components/WalletPage.vue';
import SignUp from '../components/Auth/SignUp.vue';
import SignIn from '../components/Auth/SignIn.vue';
import AdminPanel from '../components/AdminPanel.vue';
import SupportPage from '../components/SupportPage.vue';
import ForgotPassword from '../components/ForgotPassword.vue';
import ComplaintDetailPage from '../components/ComplaintDetailPage.vue';
import HowPage from '@/components/HowPage.vue';
import AboutPage from '@/components/AboutPage.vue';
import TermsOfService from '@/components/TermsOfService.vue';
import TraderPanel from '@/components/TraderPanel.vue';
import DrawPage from '@/components/DrawPage.vue';

const routes = [
    {
        path: '/signup',
        name: 'signUp',
        component: SignUp,
        beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated) {
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/signin',
        name: 'signIn',
        component: SignIn,
        beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated) {
                next();
            } else {
                next('/');
            }
        }
    },
    {
        path: '/',
        name: 'adsPage',
        component: AdsPage,
        beforeEnter(to, from, next) {
            if (store.getters.isAuthenticated) {
                if (store.getters.isBanned) {
                    next('/support');
                } else {
                    next();
                }
            } else {
                next('/signin');
            }
        }
    },
    {
        path: '/profil',
        name: 'profilPage',
        component: ProfilPage,
        beforeEnter(to, from, next) {
            if (store.getters.isAuthenticated) {
                if (store.getters.isBanned) {
                    next('/support');
                } else {
                    next();
                }
            } else {
                next('/signin');
            }
        }
    },
    {
        path: '/draw',
        name: 'drawPage',
        component: DrawPage,
        beforeEnter(to, from, next) {
            if (store.getters.isAuthenticated) {
                if (store.getters.isBanned) {
                    next('/support');
                } else {
                    next();
                }
            } else {
                next('/signin');
            }
        }
    },
    {
        path: '/wallet',
        name: 'WalletPage',
        component: WalletPage,
        beforeEnter(to, from, next) {
            if (store.getters.isAuthenticated) {
                if (store.getters.isBanned) {
                    next('/support');
                } else {
                    next();
                }
            } else {
                next('/signin');
            }
        }
    },
    {
        path: '/admin',
        name: 'AdminPanel',
        component: AdminPanel,
        beforeEnter: async (to, from, next) => {
            const localId = localStorage.getItem('localId');
            if (!localId) {
                return next('/signin');
            }
            try {
                const token = store.state.token;
                const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${token}`);
                const user = response.data;
                if (user.isAdmin) {
                    if (user.isBanned) {
                        next('/support');
                    } else {
                        next();
                    }
                } else {
                    next('/');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                next('/signin');
            }
        }
    },
    {
        path: '/trader',
        name: 'TraderPanel',
        component: TraderPanel,
        beforeEnter: async (to, from, next) => {
            const localId = localStorage.getItem('localId');
            if (!localId) {
                return next('/signin');
            }
            try {
                const token = store.state.token;
                const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/users/${localId}.json?auth=${token}`);
                const user = response.data;
                if (user.isTrader) {
                    if (user.isBanned) {
                        next('/support');
                    } else {
                        next();
                    }
                } else {
                    next('/');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                next('/signin');
            }
        }
    },
    {
        path: '/support',
        name: 'SupportPage',
        component: SupportPage,
        beforeEnter(to, from, next) {
            if (store.getters.isAuthenticated) {
                next();
            } else {
                next('/signin');
            }
        }
    },
    {
        path: '/complaint/:complaintId',
        name: 'ComplaintDetailPage',
        component: ComplaintDetailPage,
        beforeEnter(to, from, next) {
            if (store.getters.isAuthenticated) {
                if (store.getters.isBanned) {
                    next('/support');
                } else {
                    next();
                }
            } else {
                next('/signin');
            }
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/how',
        name: 'HowPage',
        component: HowPage,
    },
    {
        path: '/about',
        name: 'AboutPage',
        component: AboutPage,
    },
    {
        path: '/terms',
        name: 'TermsOfService',
        component: TermsOfService
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;