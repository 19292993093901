<template>
    <div class="draw-container">
      <div class="header">
        <h1>Çekilişler</h1>
        <p>Katıl ve büyük ödülleri kazanma şansını yakala!</p>
      </div>
  
      <div v-if="activeDraws.length || completedDraws.length">
        <div class="draw-list">
          <!-- Aktif Çekilişler -->
          <div v-for="(draw, index) in activeDraws" :key="index" class="draw-card">
            <div class="draw-info">
              <h2>{{ draw.title }}</h2>
              <p>{{ draw.description }}</p>
              <p><strong>Sponsor:</strong> {{ draw.sponsor }}</p>
              <p><strong>Kazanan Başına Gold:</strong> {{ draw.goldPerWinner }}M</p>
              <p><strong>Çekiliş Bitiş Tarihi:</strong> {{ draw.endDate }}</p>
              <button v-if="!draw.isJoined" @click="joinDraw(draw.id)">Katıl</button>
              <div v-if="draw.isWinner">
                <h3>Kazandınız!</h3>
                <video controls v-if="draw.videoUrl" :src="draw.videoUrl"></video>
              </div>
            </div>
          </div>
  
          <!-- Sonuçlandırılmış Çekilişler -->
          <div v-for="(draw, index) in completedDraws" :key="index" class="draw-card completed">
            <div class="draw-info">
              <h2>{{ draw.title }} <span class="completed-text">(Sonuçlandırıldı)</span></h2>
              <p>{{ draw.description }}</p>
              <p><strong>Sponsor:</strong> {{ draw.sponsor }}</p>
              <p><strong>Kazanan Başına Gold:</strong> {{ draw.goldPerWinner }}M</p>
              <p><strong>Çekiliş Bitiş Tarihi:</strong> {{ draw.endDate }}</p>
              <div v-if="draw.videoUrl">
                <h3>Sonuçlandırma Videosu:</h3>
                <video controls :src="draw.videoUrl"></video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Aktif veya sonuçlandırılmış çekiliş bulunmamaktadır.</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        activeDraws: [],
        completedDraws: [],
        localId: localStorage.getItem('localId'),
      };
    },
    methods: {
      async fetchDraws() {
        const idToken = await this.$store.dispatch('getToken');
        try {
          const response = await axios.get(`https://golder-31299-default-rtdb.firebaseio.com/draws.json?auth=${idToken}`);
          const drawsData = response.data;
          
          // Çekilişler listesi üzerinde işlem yaparken participants alanını kontrol et
          const formattedDraws = Object.keys(drawsData).map(key => ({
            id: key,
            ...drawsData[key],
            isJoined: drawsData[key].participants ? Object.keys(drawsData[key].participants).includes(this.localId) : false,
          }));
  
          // Aktif ve pasif (sonuçlandırılmış) çekilişleri ayırma
          this.activeDraws = formattedDraws.filter(draw => draw.isActive);
          this.completedDraws = formattedDraws.filter(draw => !draw.isActive);
        } catch (error) {
          console.error('Çekilişler getirilirken hata oluştu:', error);
        }
      },
      async joinDraw(drawId) {
        const idToken = await this.$store.dispatch('getToken');
        try {
          await axios.patch(`https://golder-31299-default-rtdb.firebaseio.com/draws/${drawId}/participants.json?auth=${idToken}`, {
            [this.localId]: true,
          });
          alert('Çekilişe katıldınız!');
          this.fetchDraws();
        } catch (error) {
          console.error('Çekilişe katılırken hata oluştu:', error);
        }
      },
    },
    async mounted() {
      await this.fetchDraws();
    },
  };
  </script>
  
  <style scoped>
  .draw-container {
    padding: 20px;
    background-color: #f0f7ff;
  }
  
  .header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .header h1 {
    color: #4169E1;
  }
  
  .draw-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .draw-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .draw-card.completed {
    background-color: #f5f5f5;
  }
  
  .draw-info h2 {
    color: #4169E1;
  }
  
  button {
    padding: 10px;
    background-color: #4169E1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #FFA500;
  }
  
  .completed-text {
    color: #dc3545;
    font-weight: bold;
  }
  
  video {
    width: 100%;
    margin-top: 10px;
  }
  </style>