<template>
    <div class="container">
      <!-- <div class="campaignCard">
        <div class="campaignHeader">Büyük Fırsat!</div>
        
        <div class="counter">
          <span v-if="count !== null" class="counterNumber">{{ count }}</span>
          <span v-else class="loading">Yükleniyor...</span>
          kişi bugün kaydoldu! <br>
          <span class="registered">(Her saat güncellenir)</span>
        </div>
        
        <hr>
        
        <div class="campaignText">
          Hemen kayıt ol, hesabını doğrula ve <strong>100k gold</strong> kazan!
        </div>
        <div class="campaignCTA">
          Fırsatı kaçırma, hemen kayıt ol!
        </div>
      </div> -->
  
      <div class="loginCard">
        <!-- <div class="titles errorLogin">AgarZ hesabınla giriş yapmana gerek yok. Kayıt olman yeterli.</div> -->
        <div v-if="isError" class="titles errorLogin">Bilgiler hatalı, lütfen kontrol et!</div>
        <div class="sections">
          <div class="titles">E-mail</div>
          <input class="input" v-model="user.email" type="text" />
        </div>
        <div class="sections">
          <div class="titles">Şifre</div>
          <input class="input" v-model="user.password" type="password" />
        </div>
        <div class="sectionsHelp">
          <router-link class="sectionsHelp-x" to="/forgot-password">Şifreni mi unuttun?</router-link>
          <router-link class="sectionsHelp-x" to="/signup">Hesabın yok mu? Kayıt ol!</router-link>
        </div>
        <div class="sectionsBtn">
          <button class="sectionsBtn-x" @click="signIn">Giriş Yap</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'SignIn',
    data() {
      return {
        user: {
          email: '',
          password: '',
        },
        count: null, // Yükleniyor durumu için başlangıçta null
      };
    },
    async mounted() {
      await this.getCounter();
      this.startCounter();
    },
    methods: {
      async getCounter() {
        try {
          const response = await axios.get('https://golder-31299-default-rtdb.firebaseio.com/counter.json');
          this.count = response.data || 3;
        } catch (error) {
          console.error('Sayaç değeri çekilirken hata oluştu:', error);
        }
      },
      async updateCounter(newCount) {
        try {
          await axios.put('https://golder-31299-default-rtdb.firebaseio.com/counter.json', newCount);
        } catch (error) {
          console.error('Sayaç güncellenirken hata oluştu:', error);
        }
      },
      startCounter() {
        setInterval(async () => {
          const randomIncrease = Math.floor(Math.random() * 6) + 15;
          const newCount = this.count + randomIncrease;
          await this.updateCounter(newCount);
          this.count = newCount;
        }, 3600000);
      },
      async signIn() {
        try {
          await this.$store.dispatch('signIn', { ...this.user });
          this.$router.push('/profil');
        } catch (error) {
          console.error(error);
          this.$store.commit('setError', 'Giriş hatalı!');
        }
      }
    },
    computed: {
      isError() {
        return this.$store.getters.isError;
      }
    },
    watch: {
      user: {
        handler() {
          if (this.isError) {
            this.$store.commit('clearError');
          }
        },
        deep: true
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f7ff;
    min-height: 100vh;
    padding: 20px;
  }
  
  .campaignCard {
    background-color: #fffbec;
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
  }
  
  .campaignHeader {
    font-size: 28px;
    font-weight: bold;
    color: #ffa500;
    margin-bottom: 15px;
  }
  
  .counter {
    font-size: 22px;
    font-weight: 700;
    color: #4169e1;
    margin-bottom: 15px;
  }
  
  .counterNumber {
    font-size: 28px;
    color: #ff4500;
  }
  
  .loading {
    font-size: 20px;
    color: #888;
  }
  
  .campaignText {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .campaignCTA {
    font-size: 20px;
    font-weight: bold;
    color: #ffa500;
  }
  
  .loginCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 20px #4169e15b;
    transition: transform 0.3s ease;
  }
  
  .loginCard:hover {
    transform: translateY(-5px);
  }
  
  .sections {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px 0;
  }
  
  .titles {
    font-size: 14px;
    font-weight: 700;
    color: #333;
    margin-bottom: 8px;
  }
  
  .input {
    padding: 12px;
    font-size: 16px;
    border: 0.4px solid #4169E1;
    border-radius: 6px;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .input:focus {
    border-color: #ffa500;
    box-shadow: 0 0 0 3px #ffa6005f;
    outline: none;
  }
  
  .sectionsBtn {
    width: 100%;
    padding: 20px 0;
  }
  
  .sectionsBtn-x {
    width: 100%;
    padding: 15px;
    background-color: #4169e1;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .sectionsBtn-x:hover {
    background-color: #ffa500;
    transform: translateY(-2px);
  }
  
  .sectionsHelp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
  }
  
  .sectionsHelp-x {
    font-size: 14px;
    color: #4169e1;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .sectionsHelp-x:hover {
    color: #4169e1a6;
  }
  
  .errorLogin {
    font-size: 14px;
    color: #dc3545;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 700;
  }
  
  @media (max-width: 768px) {
    .campaignCard {
      padding: 20px;
      max-width: 100%;
    }
  
    .campaignHeader,
    .counter,
    .campaignText,
    .campaignCTA {
      font-size: 16px;
    }
  
    .loginCard {
      padding: 20px;
      max-width: 100%;
    }
  
    .titles {
      font-size: 12px;
    }
  
    .input {
      padding: 10px;
      font-size: 14px;
    }
  
    .sectionsBtn-x {
      padding: 12px;
      font-size: 14px;
    }
  
    .sectionsHelp-x {
      font-size: 12px;
    }
  
    .errorLogin {
      font-size: 12px;
    }
  }
  </style>